import React, { Component } from 'react';

import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import withRouter from '../../../src/WithRouter';
import PageTitle from '../../layouts/PageTitle';
import DOMPurify from 'dompurify';

import { ajaxCall, closeAlert, errorAction, formatAmount, Preloader, ADMINEND_URL, tinyAlert, confirmPrompt } from '../../helpers/Helpers'


class SignInvoice extends Component {
  componentDidMount() {
    this.GetInvoiceByID();
  }

  state = {
    signingURL: '',
    form: {
      document: []
    },
    selectedFiles: [],
    status: '',
    invoice_no: '',
    tinNo: '',
    Stepper: [],
    isReviewed: false,
  }

  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }

  GetInvoiceByID = () => {
    Preloader();
    let invoice_no = this.props.routerParams.id;

    ajaxCall('Invoice/' + invoice_no, 'json').then(res => {
      let data = res.data.data;
      this.setState({ invoice_no: invoice_no, status: data.status, tinNo: data.tinNo }, this.GenerateInvoicePdf);
    })
      .catch(error => {
        errorAction(error)
      })
  }

  GenerateInvoicePdf = () => {
    ajaxCall('Invoice/GetInvoicePdf/' + this.state.invoice_no, 'blob').then(res => {
      closeAlert();
      var data_url = URL.createObjectURL(res.data);
      this.setState({ signingURL: data_url });

      this.createStepper();
    })
      .catch(error => {
        errorAction(error)
      })
  }

  storeFile = (e) => {
    let formfiles = e.target.files;
    let docnames = [];
    for (let i = 0; i < formfiles.length; i++) {
      let file = {}
      file.name = formfiles[i].name;
      file.type = formfiles[i].type;
      file.size = formfiles[i].size;

      docnames.push(file);
    }
    this.setState({ selectedFiles: docnames });
  }

  SubmitInvoice = (e) => {
    e.preventDefault();

    // if (!this.state.tinNo) {
    //   errorAlert('unable to create an invoice at the moment because our records show you do not have a Tax Identification Number (TIN) registered in our system. To register your TIN please reach out to <a href="mailto:vendormasterdata-financeng@totalenergies.com">vendormasterdata-financeng@totalenergies.com</a>');
    //   return;
    // }

    confirmPrompt("Do you want to submit invoice ?").then((r) => r.isConfirmed ? this.handleSubmit(e) : false)
  }

  handleSubmit = (e) => {
    Preloader();

    const formData = new FormData();
    for (let i = 0; i < e.target[0].files.length; i++) {
      formData.append("SupportingDocuments", e.target[0].files[i]);
    }

    formData.append("AdminViewLink", ADMINEND_URL + '/view-invoice/' + this.state.invoice_no);
    ajaxCall('Invoice/SubmitInvoice/' + this.state.invoice_no + '?verifyEffectiveSubmitDateTime=true', 'json', 'patch', formData).then(res => {
      tinyAlert('success', 'Invoice submitted successfully');
      this.GetInvoiceByID();
    })
      .catch(error => {
        if (error?.response?.status) {
          if (error.response.status === 400 && error?.response.data.message === "DifferentEffectiveSubmitDate") {
            let effective_date = new Date(error.response.data.data).toDateString();
            confirmPrompt("Your invoice effective date will be on " + effective_date + " do you want to continue?").then((r) => r.isConfirmed ? this.handleSubmitEffectiveDate(e) : false);
          }
          else {
            errorAction(error);
          }
        }
      });
  }

  handleSubmitEffectiveDate = (e) => {
    Preloader();

    const formData = new FormData();
    for (let i = 0; i < e.target[0].files.length; i++) {
      formData.append("SupportingDocuments", e.target[0].files[i]);
    }

    formData.append("AdminViewLink", ADMINEND_URL + '/view-invoice/' + this.state.invoice_no);
    ajaxCall('Invoice/SubmitInvoice/' + this.state.invoice_no + '?verifyEffectiveSubmitDateTime=false', 'json', 'patch', formData).then(res => {
      tinyAlert('success', 'Invoice submitted successfully');
      this.GetInvoiceByID();
    })
      .catch(error => {
        errorAction(error);
      });
  }

  createStepper = () => {
    let i = ['Draft', 'Submitted', 'Not Found', 'Undergoing Processing', 'Awaiting Payment', 'Paid', 'Rejected', 'Rejected by Admin'];
    let a = ['', 'complete', 'current', 'terminate', 'success'];
    let stepper = [];

    switch (this.state.status) {
      case 'Draft': stepper = [{ k: i[0], v: a[2] }, { k: i[1], v: a[0] }, { k: i[3], v: a[0] }, { k: i[4], v: a[0] }, { k: i[5], v: a[0] }]; break;
      case 'Submitted': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[2] }, { k: i[3], v: a[0] }, { k: i[4], v: a[0] }, { k: i[5], v: a[0] }]; break;
      case 'Undergoing Processing': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[2] }, { k: i[4], v: a[0] }, { k: i[5], v: a[0] }]; break;
      case 'Awaiting Payment': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[1] }, { k: i[4], v: a[2] }, { k: i[5], v: a[0] }]; break;
      case 'Paid': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[1] }, { k: i[4], v: a[1] }, { k: i[5], v: a[4] }]; break;
      case 'Not Found': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[2], v: a[3] }]; break;
      case 'Rejected': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[1] }, { k: i[6], v: a[3] }]; break;
      case 'Rejected by Admin': stepper = [{ k: i[0], v: a[1] }, { k: i[7], v: a[3] }]; break;
      default: stepper = [];
    }

    this.setState({ Stepper: stepper });
  }

  documentReview = (e) => {
    console.log(e.target.checked);
    this.setState({ isReviewed: e.target.checked });
  }

  render() {
    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            <SideNav />

            <div className='main-content-wrap'>
              <TopNav />
              <PageTitle pagedata={{ page: 'Invoice', breadcrumb: 'View Invoice' }} />

              <div className='container-fluid mb-5'>
                <div className='row'>

                  <div className='col-12 px-4 mt-3'>
                    <ul className="stepper">
                      {
                        this.state.Stepper.map((x, i) => {
                          return <li className={`stepper__item ${x.v}`} key={i}>{x.k}</li>
                        })
                      }
                    </ul>
                  </div>

                  <div className='col-12 px-4 mb-5'>
                    <div className="shadow bg-white mb-5">
                      <div className='pt-3 px-4'>
                        <iframe src={DOMPurify.sanitize(this.state.signingURL)} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>

                        {this.state.status === 'Draft' &&
                          <form className="login-form" autoComplete="off" method="post" onSubmit={this.SubmitInvoice}>
                            <div className='row mt-3'>
                              <div className='col-12 col-sm-3'>
                                <label className='bolder font-14'>Upload Supporting Documents: </label>
                              </div>

                              <div className='col-9 col-sm-9'>
                                <div className="form-group mb-0">
                                  <input type="file" name='document' onChange={this.storeFile} autoFocus className="form-control input-font" autoComplete="off" placeholder="Upload your docuemnts (PDF format only)" style={{ height: '35px' }} multiple accept=".pdf" />
                                </div>
                              </div>
                            </div>

                            <div className='row mt-3'>
                              <div className='col-sm-12'>
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                  <thead className='text-white' style={{ background: '#0556de' }}>
                                    <tr>
                                      <th className='text-center'>SN</th>
                                      <th>File Name</th>
                                      <th>Type</th>
                                      <th>Size</th>
                                    </tr>
                                  </thead>
                                  <tbody className='font-12'>
                                    {
                                      this.state.selectedFiles.map((o, i) => {
                                        return <tr key={i}>
                                          <td className="text-center">{i + 1}</td>
                                          <td>{o.name}</td>
                                          <td>{o.type}</td>
                                          <td>{formatAmount((o.size / 1024) / 1024)} Mb</td>
                                        </tr>
                                      })
                                    }
                                  </tbody>
                                </table>

                              </div>
                            </div>


                            <div className="row mt-3">
                              <div className='col-12'>
                                <p className='alert alert-primary text-center font-12'>
                                  <input type="checkbox" className='' checked={this.state.isReviewed} onChange={this.documentReview} />
                                  <span style={{ paddingLeft: '10px', top: '-3px', position: 'relative' }}>"I confirm that I have reviewed and validated the information on the invoice and I understand that I cannot make any changes to this invoice once it is submitted"</span>
                                </p>
                              </div>
                              <div className='col-sm-10'></div>
                              <div className='col-sm-2'>
                                <div className="form-group mt-0 mb-5">
                                  <button type='submit' className='btn btn-primary font-12 border-radius-sm form-control' disabled={!this.state.isReviewed}>Submit</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignInvoice);