import React, { Component } from 'react';
import Select from 'react-select'
import DOMPurify from 'dompurify';

// import layout
import withRouter from '../../../src/WithRouter';
import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import PageTitle from '../../layouts/PageTitle';

// import self required methods;
import { ajaxCall, errorAction, successAlert, confirmPrompt, closeAlert, Preloader, ADMINEND_URL, tinyAlert, errorAlert, formatDate } from '../../helpers/Helpers'


class SignFIInvoice extends Component {
  componentDidMount() {
    this.GetInvoiceByID();
  }

  state = {
    InvoicePDF: '',
    FIDocuments: [],
    FIExtraDocuments: [],
    FIUploadedDocuments: [],
    FIDepartment: [],
    form: {
      fiDepartment: []
    },
    invoice_no: '',
    tinNo: '',
    status: '',
    categoryID: '',
    Stepper: [],
    invoiceRecord: {},
    isReviewed: false,
  }

  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }
  handleFormSelectChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.name]: [e] } })) }

  GetInvoiceByID = () => {
    Preloader();
    let invoice_no = this.props.routerParams.id;

    ajaxCall('Invoice/' + invoice_no, 'json').then(res => {
      let data = res.data.data;
      this.setState({ invoice_no: invoice_no, status: data.status, categoryID: data.fiCategoryId, invoiceRecord: data, tinNo: data.tinNo }, this.GenerateInvoicePdf);
    })
      .catch(error => {
        errorAction(error)
      })
  }

  GenerateInvoicePdf = () => {
    Preloader();
    ajaxCall("Invoice/GetInvoicePdf/" + this.state.invoice_no, 'blob').then(res => {
      this.setState({ InvoicePDF: URL.createObjectURL(res.data) });
      this.GetFIInvoiceDocuments();
      this.createStepper();
    })
      .catch(error => { errorAction(error) })
  }

  GetFIInvoiceDocuments = () => {
    ajaxCall("Invoice/GetFIInvoiceDocuments/" + this.state.invoice_no).then(res => {
      this.setState({ FIUploadedDocuments: res.data.data }, this.GetFICategories);
    })
      .catch(error => { errorAction(error) })
  }

  GetFICategories = () => {
    ajaxCall("FICategory/").then(res => {
      closeAlert();
      let record = res.data.data.filter(g => (g.id === Number(this.state.categoryID)))[0].requiredDocumentsList;
      this.setState({ FIDocuments: record });

      //FIExtraDocuments lists
      let uploadedDocList = this.state.FIUploadedDocuments.filter(g => !(record.includes(g.documentTypeName)));
      let listFilter = uploadedDocList.map(item => { return item.documentTypeName; })

      if (uploadedDocList.length > 0)
        this.setState({ FIExtraDocuments: listFilter });
      else
        this.setState({ FIExtraDocuments: [] });

      this.GetFIDepartment();
    })
      .catch(error => { errorAction(error) })
  }

  GetFIDepartment = () => {
    ajaxCall("FIDept").then(res => {
      closeAlert();
      let record = res.data.data.filter(g => (g.active === true));
      let list = record.map((r) => ({ value: r.id, name: 'fiDepartment', label: r.dept }));
      this.setState({ FIDepartment: list });
    })
      .catch(error => { errorAction(error) })
  }

  AddNewUploadRow = () => {
    let record = this.state.FIExtraDocuments;
    record.push('');

    this.setState({ FIExtraDocuments: record });
  }

  InputExtraDocumentName = (e, i) => {
    let inputtedValue = e.target.value;
    let record = this.state.FIExtraDocuments;
    record[i] = inputtedValue;

    this.setState({ FIExtraDocuments: record });
  }

  UploadDocument = (i) => {

    /** @type HTMLInputElement */
    let fileInput = document.querySelector("#documentFile" + i);
    let file = fileInput.files[0]


    /** @type HTMLInputElement */
    let nameInput = document.querySelector("#documentName" + i);
    let name = nameInput.value;


    if (name === '') { tinyAlert('error', "Please enter the document name to continue"); return; }
    if (file === undefined) { tinyAlert('error', "Please choose a document to continue"); return; }

    let formData = new FormData();
    formData.append("Document", file);
    formData.append("DocumentTypeName", name);


    Preloader();
    ajaxCall("Invoice/UploadInvoiceFIDocument/" + this.state.invoice_no, 'json', 'post', formData).then(res => {
      this.GetFIInvoiceDocuments();
      tinyAlert('success', name + ' document uploaded successfully');
    })
      .catch(error => { errorAction(error) })
  }

  ShowDocumentName = (o) => {
    if (this.state.FIUploadedDocuments.filter(g => (g.documentTypeName === o)).length > 0)
      return this.state.FIUploadedDocuments.filter(g => (g.documentTypeName === o))[0].documentTypeName
  }

  DownloadDocument = (filename) => {
    Preloader();
    ajaxCall("Invoice/DownloadInvoiceFIDocument/" + this.state.invoice_no + "/" + encodeURIComponent(filename), 'blob').then(res => {
      closeAlert();
      let objecturl = URL.createObjectURL(res.data);
      let win = window.open(objecturl, '_blank');

      if (win) window.focus();
      else {
        window.setTimeout(function () { if (win) win.focus(); }, 100);
      }
    })
      .catch(error => { errorAction(error) })
  }

  DownloadAllDocument = () => {
    Preloader();
    ajaxCall("Invoice/DownloadAllInvoiceFIDocuments/" + this.state.invoice_no, 'blob').then(res => {
      closeAlert();

      let objecturl = URL.createObjectURL(res.data);
      let win = window.open(objecturl, '_blank');

      if (win) window.focus();
      else {
        window.setTimeout(function () { if (win) win.focus(); }, 100);
      }

    })
      .catch(error => { errorAction(error) })
  }

  DeleteDocument = (filename) => {
    Preloader();
    ajaxCall("Invoice/DeleteInvoiceFIDocument/" + this.state.invoice_no + "/" + filename, 'json', 'delete').then(res => {
      closeAlert();
      this.GetFIInvoiceDocuments();
      successAlert(filename + ' document deleted successfully')
    })
      .catch(error => { errorAction(error) })
  }

  SendForTechReview = (e) => {
    // if (!this.state.tinNo) {
    //   errorAlert('unable to create an invoice at the moment because our records show you do not have a Tax Identification Number (TIN) registered in our system. To register your TIN please reach out to <a href="mailto:vendormasterdata-financeng@totalenergies.com">vendormasterdata-financeng@totalenergies.com</a>');
    //   return;
    // }

    console.log(this.state.form.fiDepartment)
    if (this.state.form.fiDepartment.length === 0) {
      errorAlert("Kindly select your User's Department");
      return;
    }

    let data = {
      "fiDeptId": this.state.form.fiDepartment[0].value,
      "invoiceReviewLink": ADMINEND_URL + '/view-invoice/' + this.state.invoice_no,
      "adminViewLink": ADMINEND_URL + '/view-invoice/' + this.state.invoice_no
    }

    Preloader();

    /** @type HTMLInputElement */
    // let refElement = document.querySelector("#submitTechRep");
    // let button_name = refElement.innerHTML;
    // refElement.disabled = true;
    // refElement.innerHTML = Spinner;

    ajaxCall("/Invoice/SendFIInvoiceForReview/" + this.state.invoice_no, 'json', 'patch', data).then(res => {
      closeAlert();
      tinyAlert("success", "Your invoice was submitted for review successfully!");

      // refElement.innerHTML = "Documents subimitted for review";
      this.GetInvoiceByID();
    })
      .catch(error => {
        // refElement.disabled = false;
        // refElement.innerHTML = button_name;

        errorAction(error);
      })
  }

  SubmitInvoice = () => {
    // e.preventDefault();
    // if (!this.state.tinNo) {
    //   errorAlert('unable to create an invoice at the moment because our records show you do not have a Tax Identification Number (TIN) registered in our system. To register your TIN please reach out to <a href="mailto:vendormasterdata-financeng@totalenergies.com">vendormasterdata-financeng@totalenergies.com</a>');
    //   return;
    // }

    confirmPrompt("Do you want to submit invoice ?").then((r) => r.isConfirmed ? this.handleSubmit() : false)
  }

  handleSubmit = () => {
    Preloader();

    const formData = new FormData();
    formData.append("SupportingDocuments", "");
    formData.append("AdminViewLink", ADMINEND_URL + '/view-invoice/' + this.state.invoice_no);

    ajaxCall('Invoice/SubmitInvoice/' + this.state.invoice_no + '?verifyEffectiveSubmitDateTime=true', 'json', 'patch', formData).then(res => {
      tinyAlert('success', 'Invoice submitted successfully');
      this.GetInvoiceByID();
    })
      .catch(error => {
        if (error?.response?.status) {
          if (error.response.status === 400 && error?.response.data.message === "DifferentEffectiveSubmitDate") {
            let effective_date = new Date(error.response.data.data).toDateString();
            confirmPrompt("Your invoice effective date will be on " + effective_date + " do you want to continue?").then((r) => r.isConfirmed ? this.handleSubmitEffectiveDate() : false);
          }
          else {
            errorAction(error);
          }
        }
      });
  }

  handleSubmitEffectiveDate = () => {
    Preloader();

    const formData = new FormData();
    formData.append("SupportingDocuments", "");
    formData.append("AdminViewLink", ADMINEND_URL + '/view-invoice/' + this.state.invoice_no);

    ajaxCall('Invoice/SubmitInvoice/' + this.state.invoice_no + '?verifyEffectiveSubmitDateTime=false', 'json', 'patch', formData).then(res => {
      tinyAlert('success', 'Invoice submitted successfully');
      this.GetInvoiceByID();
    })
      .catch(error => {
        errorAction(error);
      });
  }

  createStepper = () => {
    let i = ['', 'Draft', 'Awaiting Technical Rep. Review', 'Accepted by Technical Rep.', 'Rejected by Technical Rep.', 'Submitted', 'Not Found', 'Undergoing Processing', 'Awaiting Payment', 'Paid', 'Rejected', 'Rejected by Admin'];
    let a = ['', 'complete', 'current', 'terminate', 'success'];
    let stepper = [];

    switch (this.state.status) {
      case 'Draft': stepper = [{ k: i[1], v: a[2] }, { k: i[2], v: a[0] }, { k: i[3], v: a[0] }, { k: i[5], v: a[0] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Awaiting Technical Rep. Review': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[2] }, { k: i[3], v: a[0] }, { k: i[5], v: a[0] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Accepted by Technical Rep.': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[2] }, { k: i[5], v: a[0] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Submitted': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[2] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Undergoing Processing': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[2] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Awaiting Payment': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[1] }, { k: i[8], v: a[2] }, { k: i[9], v: a[0] }]; break;
      case 'Paid': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[1] }, { k: i[8], v: a[1] }, { k: i[9], v: a[4] }]; break;
      case 'Rejected by Technical Rep.': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[4], v: a[3] }]; break;
      case 'Not Found': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[6], v: a[3] }]; break;
      case 'Rejected': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[1] }, { k: i[10], v: a[3] }]; break;
      case 'Rejected by Admin':
        if (this.state.invoice.sentForFITechnicalRepReviewDate == null) // previously Draft
          stepper = [{ k: i[1], v: a[1] }, { k: i[11], v: a[3] }];
        else if (this.state.invoice.fITechnicalRepReviewedDate == null) // previously Awaiting Technical Rep. Review or Rejected by Technical Rep.
          stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[11], v: a[3] }];
        else // previously Accepted by Technical Rep.
          stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[11], v: a[3] }];
        break;
      default: stepper = [];
    }

    this.setState({ Stepper: stepper });
  }

  documentReview = (e) => {
    console.log(e.target.checked);
    this.setState({ isReviewed: e.target.checked });
  }

  render() {
    const page_info = { page: 'Submit Non-PO Invoice', breadcrumb: 'Invoice' };
    const store = this.state;

    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            <SideNav />

            <div className='main-content-wrap'>
              <TopNav />
              <PageTitle pagedata={page_info} />

              <div className='container-fluid mb-5'>
                <div className='row'>
                  <div className='col-12 px-4 mt-3'>
                    <ul className="stepper">
                      {
                        store.Stepper.map((x, i) => {
                          return <li className={`stepper__item ${x.v}`} key={i}><span>{x.k}</span></li>
                        })
                      }
                    </ul>
                  </div>


                  {/*! reason for Technical rep rejection */}
                  {
                    this.state.status === 'Rejected by Technical Rep.' &&
                    <div className='col-12 px-4 mb-2'>
                      <div className="shadow bg-white px-4 py-4">
                        <ul className="timeline-with-icons">
                          <li className="timeline-item">
                            <span className="timeline-icon bg-danger">
                              <i className="fa fa-envelope-o"></i>
                            </span>

                            <h5 className="fw-bold font-14 pt-2">Comment by Technical Rep.</h5>
                            <p className='text-muted mb-0 font-12'>-{store.invoiceRecord.fiTechnicalRepReviewer}</p>
                            <p className="text-muted mb-1 font-12">{formatDate(this.state.invoiceRecord.fiTechnicalRepReviewedDate, 'date')}</p>

                            <p className="text-muted font-14">
                              {this.state.invoiceRecord.fiTechnicalRepReviewComments}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  }


                  <div className='col-12 px-4 mb-5'>
                    <div className="shadow bg-white mb-5 px-4 py-4">
                      <iframe src={DOMPurify.sanitize(this.state.InvoicePDF)} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>

                      <div className=''>
                        <form className="login-form" autoComplete="off" method="post">
                          {/* {
                              this.state.status !== 'Accepted by Technical Rep.' &&  */}
                          <div>
                            <div className='row'>
                              <div className='col-sm-12'>
                                <div>
                                  <h6 className='mt-3'>Support Documents</h6>
                                </div>
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                  <thead className='text-white' style={{ background: '#0556de' }}>
                                    <tr>
                                      <th className='text-center' style={{ width: '50px' }}>SN</th>
                                      <th>Document Name</th>
                                      <th style={{ width: '30%' }}>Select Document</th>
                                      <th style={{ width: '30%' }}>Action</th>
                                    </tr>
                                  </thead>

                                  <tbody className='font-12'>
                                    {
                                      this.state.FIDocuments.map((o, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="text-center"><p className='mt-2 mb-0'>{i + 1}</p></td>
                                            <td>
                                              <input placeholder='Other Support Document' disabled id={'documentName' + i} type='text' value={o} className='form-control font-12 border-radius-sm' />
                                            </td>
                                            <td>
                                              <input placeholder='Upload file' accept=".pdf" disabled={this.ShowDocumentName(o) !== undefined} id={'documentFile' + i} type='file' className='form-control font-12 border-radius-sm' onChange={() => this.UploadDocument(i)} />
                                            </td>
                                            <td className='d-flex  justify-content-between'>
                                              <div>
                                                <button type='button' disabled={this.ShowDocumentName(o) !== undefined} name={'documentBtn' + i} onClick={() => this.UploadDocument(i)} className='btn btn-primary font-12 border-radius-sm'>Upload Document</button>
                                                {
                                                  this.ShowDocumentName(o) === undefined &&
                                                  <span className='text-danger font-12' style={{ paddingLeft: '5px' }}>* Document is required</span>
                                                }
                                              </div>

                                              {this.ShowDocumentName(o) !== undefined &&
                                                <div>
                                                  <button type='button' onClick={() => this.DownloadDocument(this.ShowDocumentName(o))} title='Download Document' className='btn btn-primary font-12 border-radius-sm'><i className="fa fa-download"></i> View Document</button>&nbsp;&nbsp;


                                                  {this.state.status !== 'Submitted' ?
                                                    <button type='button' onClick={() => confirmPrompt("Do you want to delete document?").then((r) => r.isConfirmed ? this.DeleteDocument(this.ShowDocumentName(o)) : false)} title='Delete Document' className='btn btn-danger font-12 border-radius-sm'><i className="fa fa-trash"></i> Delete</button>
                                                    :
                                                    <></>
                                                  }
                                                </div>
                                              }
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                    {
                                      this.state.FIExtraDocuments.map((o, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="text-center"><p className='mt-2 mb-0'>{i + 1 + this.state.FIDocuments.length}</p></td>
                                            <td>
                                              <input placeholder='Other Support Document' onChange={(e) => this.InputExtraDocumentName(e, i)} id={'documentNameExtra' + i} type='text' value={o} className='form-control font-12 border-radius-sm' />
                                            </td>
                                            <td>
                                              <input placeholder='Upload file' disabled={this.ShowDocumentName(o) !== undefined} accept=".pdf" id={'documentFileExtra' + i} type='file' className='form-control font-12 border-radius-sm' />
                                            </td>
                                            <td className='d-none'>
                                              <div className='mt-1 d-flex  justify-content-between'>
                                                {this.ShowDocumentName(o)} &nbsp;&nbsp;

                                                {this.ShowDocumentName(o) !== undefined &&
                                                  <div>
                                                    <button type='button' className='btn btn-primary font-12 border-radius-sm fa fa-download'></button>&nbsp;&nbsp;
                                                    <button type='button' className='btn btn-danger font-12 border-radius-sm fa fa-trash'></button>
                                                  </div>
                                                }
                                              </div>
                                            </td>
                                            <td className='d-flex  justify-content-between'>
                                              <button type='button' disabled={this.ShowDocumentName(o) !== undefined} name={'documentBtn' + i} onClick={() => this.UploadDocument('Extra' + i)} className='btn btn-primary font-12 border-radius-sm'>Upload Document</button>

                                              {this.ShowDocumentName(o) !== undefined &&
                                                <div>
                                                  <button type='button' onClick={() => this.DownloadDocument(this.ShowDocumentName(o))} className='btn btn-primary font-12 border-radius-sm'><i className=" fa fa-download"></i> View Document</button>  &nbsp;&nbsp;
                                                  <button type='button' onClick={() => confirmPrompt("Do you want to delete document ?").then((r) => r.isConfirmed ? this.DeleteDocument(this.ShowDocumentName(o)) : false)} className='btn btn-danger font-12 border-radius-sm'><i className=" fa fa-trash"></i>Delete</button>
                                                </div>
                                              }
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }


                                    <tr>
                                      <td></td>
                                      <td className="">
                                        {this.state.status === 'Draft' ?
                                          <button type='button' onClick={() => this.AddNewUploadRow()} className='btn btn-primary font-12 border-radius-sm'><i className=' fa fa-plus'></i> Attach Extra Document</button>
                                          : <></>
                                        }
                                      </td>
                                      <td></td>
                                      <td className='d-none'></td>
                                      <td>
                                        <div className='text-end'>
                                          <button type='button' onClick={() => this.DownloadAllDocument()} title='Download Document' className='btn btn-primary font-12 border-radius-sm'>View All Support Documents</button>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>


                            {this.state.status === 'Draft' || this.state.status === 'Awaiting Technical Rep. Review' ?
                              <div className='row mt-3 sm-mt-1'>
                                <div className='col-sm-12 col-12'>
                                  <div className="form-group mb-0">
                                    <div className='info-alert mb-1 text-danger bold' style={{ borderLeft: '3px solid red' }}>Please endeavor to confirm the correct "User's Department" from your TotalEnergies contact</div>
                                    <Select name='fiDepartment'
                                      defaultValue={this.state.form.fiDepartment.length > 0 ? this.state.form.fiDepartment[0] : ''}
                                      onChange={this.handleFormSelectChange}
                                      isSearchable={true}
                                      placeholder="Select User's Department"
                                      options={this.state.FIDepartment}
                                    />
                                  </div>
                                </div>
                              </div>
                              : <></>
                            }
                          </div>
                          {/* } */}

                          {this.state.status !== 'Submitted' ?
                            <div className="row mt-3">

                              {this.state.status === 'Accepted by Technical Rep.' &&
                                <div className='col-12'>
                                  <div className='alert alert-danger font-14 d-flex'>
                                    <input type="checkbox" className='' checked={this.state.isReviewed} style={{ width: '17px', height: '17px' }} onChange={this.documentReview} />
                                    <div style={{ paddingLeft: '10px', top: '-3px', position: 'relative' }}>I confirm that I have reviewed and validated the information on the invoice and I understand that I cannot make any changes to this invoice once it is submitted</div>
                                  </div>
                                </div>
                              }

                              <div className='col-sm-10'></div>
                              <div className='col-sm-2'>
                                <div className="form-group mt-0 mb-5">

                                  {this.state.status === 'Draft' || this.state.status === 'Awaiting Technical Rep. Review' ?
                                    <button type='button' onClick={(e) => confirmPrompt("Do you want to send document for review?").then((r) => r.isConfirmed ? this.SendForTechReview(e) : false)} name='sendReview' className='btn btn-primary font-12 border-radius-sm form-control' id='submitTechRep' disabled={this.state.status === 'Draft' ? false : !this.state.isReviewed}>
                                      {
                                        this.state.status === 'Awaiting Technical Rep. Review' ? 'Re-Send For Review' : 'Send For Review'
                                      }
                                    </button> : ''
                                  }

                                  {this.state.status === 'Accepted by Technical Rep.' ?
                                    <button type='button' onClick={this.SubmitInvoice} disabled={!this.state.isReviewed} name='button' className='btn btn-primary font-12 border-radius-sm form-control'>Submit Invoice</button> :
                                    <></>
                                  }
                                </div>
                              </div>
                            </div>
                            :
                            <></>
                          }

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(SignFIInvoice);